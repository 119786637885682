.App {
  text-align: center;
}

.App-header {
  background-color: #F0F0F0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333333;
}

#MainSection {
  border:5px solid #333333;
  padding: 5%;
}

h1 {
  letter-spacing: -2px;
  font-size: calc(90px + 2vmin);
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  margin-block-start: .1em;
  margin-block-end: .1em;
}

h2 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: calc(30px + 2vmin);
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
}

h3 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: calc(12 + 2vmin);
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  background-color: #333333;
  color: #F0F0F0;
  padding: 0 20%;
}

#ResumeButton:link {
  text-decoration: none;
  color: #F0F0F0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
